.title-search-bar-container {
    display: flex;
    flex-direction: column; /* Elements in a column */
    gap: 10px;
  }
  
  .title-search-bar-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
  }
  
  .title-search-bar-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .title-search-bar-button:hover {
    background-color: #0056b3;
  }
  