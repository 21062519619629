/* ProfilePage.css */

body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #f4f4f4;
    color: #444;
    line-height: 1.6;
    padding: 20px;
}

.container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 40px auto;
}

h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

h2 {
    font-size: 20px;
    color: #333;
    margin-top: 30px;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 5px;
}

.profile-info {
    font-size: 16px;
    margin: 10px 0;
}

.profile-info span {
    font-weight: bold;
}

.button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.button:hover {
    background-color: #0056b3;
}

.list {
    list-style-type: none;
    padding-left: 0;
}

.list-item {
    padding: 5px 0;
}

.email {
    font-style: italic;
}