/* CourseDetails.css */
.course-details-container {
  background: #fff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 20px auto;
}

.course-title {
  font-size: 1.75rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.course-subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.course-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.course-price {
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.enrollment-notice {
  color: rgb(205, 148, 158);
  margin-top: 0.5rem;
}

.view-modules-button, .enroll-button, .edit-button, .delete-button {
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  color: #fff;
  margin: 0.5rem;
  text-decoration: none; /* Remove underline from links */
  display: inline-block; /* Allows for margins and alignment */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.view-modules-button {
  background-color: #007bff;
  color: #fff;
  padding: 6px 12px; /* Reduced padding */
  font-size: 0.8rem; /* Reduced font size */
  border: none;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block; /* Allows for margins and alignment */
  cursor: pointer;
  transition: background-color 0.2s;
}


.enroll-button {
  background-color: #28a745;
}

.edit-button {
  background-color: #ffc107;
}

.delete-button {
  background-color: #dc3545;
}

.view-modules-button:hover {
  background-color: #0056b3;
}

.enroll-button:hover {
  background-color: #218838;
}

.edit-button:hover {
  background-color: #e0a800;
}

.delete-button:hover {
  background-color: #c82333;
}

.enrolled-students h4 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.75rem;
}

.enrolled-students ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.enrolled-students li {
  padding: 0.25rem 0;
}

.enrolled-students li a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s;
}

.enrolled-students li a:hover {
  color: #0056b3;
}