/* Navbar.css */
.navbar {
    background-color: #333;
    overflow: hidden;
  }
  
  .nav-item {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  .nav-item:hover {
    background-color: #ddd;
    color: black;
  }
  