/* index.css */

/* 全局样式 */
body, html {
  margin: 0;
  padding: 0;
  background: white; /* 设置背景颜色为白色 */
  color: #333; /* 默认文字颜色 */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 为所有的元素设置box-sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

/* 设置主要内容区域 */
.main-content {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px; /* 或者您希望的最大宽度 */
}

/* 导航栏样式 */
nav {
  background: #007bff; /* 蓝色背景 */
  color: white;
  padding: 10px 20px;
}

nav a {
  color: white;
  text-decoration: none;
  margin-right: 15px;
}

nav a:hover {
  text-decoration: underline;
}

/* 链接样式 */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* 按钮样式 */
button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* 表单样式 */
input, select, textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* 表格样式 */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
}

/* 辅助类 */
.text-center {
  text-align: center;
}

.hidden {
  display: none;
}
