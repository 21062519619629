/* ModuleCard.css */
.module-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  float: left; /* Make the module cards float */
  width: calc(33.33% - 32px); /* Three cards per row with margin */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.module-card h3 {
  margin-top: 0;
}

.module-actions {
  margin-top: 16px;
}

.module-video {
  margin-top: 16px;
}

/* Clearfix for floated elements */
.course-modules-page::after {
  content: "";
  display: table;
  clear: both;
}

.edit-button,
.delete-button {
  margin-right: 10px;
}