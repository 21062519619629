/* VideoPlayer.css */
.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    width: 100%;
    max-width: 800px; /* Adjust the max-width as needed */
    margin: 0 auto; /* Center align */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  