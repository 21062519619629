/* Styles for the overall form container */
form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

/* Styles for each input field */
form input {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
}

/* Style for the placeholder text */
form input::placeholder {
    color: #999;
}

/* Styles for the submit button */
form button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

/* Hover effect for button */
form button:hover {
    background-color: #0056b3;
}