.course-modules-page {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .page-title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    font-weight: 700;
  }
  
  .loading {
    text-align: center;
    font-size: 1.5em;
    color: #333;
  }
  
  .module-list {
    margin-top: 20px;
  }
  
  .add-module-button {
    display: block;
    width: fit-content;
    margin: 10px auto 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .add-module-button:hover {
    background-color: #0056b3;
  }
  
  .module-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .module-card h3 {
    margin-top: 0;
    color: #333;
  }
  
  .module-card p {
    color: #555;
  }
  
  .module-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .edit-button, .delete-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .edit-button:hover, .delete-button:hover {
    background-color: #0056b3;
  }
  
  .module-form {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .module-form input, .module-form textarea {
    display: block;
    width: calc(100% - 20px);
    margin: 10px 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .submit-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #218838;
  }
  
  .module-video {
    margin-top: 20px;
  }
  