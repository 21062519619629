.search-bar-container {
    display: flex;
    flex-direction: column; /* Change to column layout */
    gap: 10px;
  }
  
  .search-bar-input {
    width: 100%; /* Ensure the input takes the full width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-bar-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .search-bar-button:hover {
    background-color: #0056b3;
  }