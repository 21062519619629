/* Styling for the overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Styling for the modal container */
.modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
}

/* Styling for the modal title */
.modal h2 {
    color: #333;
    margin-bottom: 15px;
}

/* Styling for the modal content */
.modal p {
    color: #666;
    margin-bottom: 20px;
}

/* Styling for buttons inside the modal */
.modal button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

/* Hover effect for buttons */
.modal button:hover {
    background-color: #0056b3;
}