.profile-form {
    display: flex;
    flex-direction: column;
    max-width: 400px; /* Adjust as needed */
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .profile-info label {
    margin-bottom: 5px;
  }
  
  .profile-info textarea,
  .profile-info input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    box-sizing: border-box; /* To include padding in width */
  }
  